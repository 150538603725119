.hero {
  /* background-image: url(./assets/hero.svg); */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

/* switch */
.ant-switch.ant-switch-small {
  background: white !important;
}

.ant-switch-handle {
  width: 50px !important;
  height: 50px !important;
  top: -5px !important;

  &:before {
    border-radius: 100% !important;
  }
}

.ant-switch-checked .ant-switch-handle {
  inset-inline-start: calc(100% - 50px) !important;

  &:before {
    background: linear-gradient(209.32deg, rgba(174, 246, 204, 0.64) -19.53%, rgba(253, 199, 125, 0.64) 92.94%);
  }
}

@media (max-width: 600px) {
  #switch {
    height: 27px !important;
    width: 80px !important;
  }

  .ant-switch-handle {
    width: 37px !important;
    height: 37px !important;
  }

  .ant-switch-checked .ant-switch-handle {
    inset-inline-start: calc(100% - 37px) !important;
  }
}

.blur-backdrop {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background-color: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #1B2761;
  border-radius: 60px;
}

.mini-scrollbar::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.container {

  background: lightgreen;
  height: auto !important;
  max-height: none !important;


  margin-top: 100px;
}

.child {
  padding: 30px;
  /*   transition: all 500ms; */
}

.container.on .child {
  padding: 10px;
}

.content {
  background-color: lightcoral;
  min-height: 400px;
}

#tsparticles {
  position: absolute;
  top: 0;
  height: 100%;
  left: auto;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  z-index: 0;
  width: 55%;
}

.about-hero {
  background: url(./assets/about-hero.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.hero-glaze {
  /* background: url(./assets/glaze.svg), rgba(0, 82, 180, 0.02); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: rgba(0, 82, 180, 0.02);
}


/* Fading animation */
.fade {
  animation-name: fade;
  animation-duration: 1.5s;
}

@keyframes fade {
  from {
    opacity: .4
  }

  to {
    opacity: 1
  }
}

@media (max-width: 768px) {
  #tsparticles {
    width: 100%;
    top: 300px;
    height: auto;
  }
}

.logos {
  animation: scroll 35s linear infinite;
}

.logos-reverse {
  animation: scroll 35s linear infinite reverse;
}

.logos img,
.logos-reverse img {
  flex-shrink: 0;
}

@keyframes scroll {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.map-country {
  animation: bounce 3s infinite;

}

@keyframes bounce {

  0%,
  100% {
    transform: translateY(-6%);
    animation-timing-function: cubic-bezier(0.5, 0, 1, 1);
  }

  50% {
    transform: translateY(0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}

.skeleton {
  position: relative;
  background: linear-gradient(269.73deg, #E2E8F0 35.65%, rgba(241, 245, 249, 0.2) 110.36%);
}

.skeleton::after {
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  background: -webkit-gradient(linear, left top,
      right top, from(transparent),
      color-stop(rgba(255, 255, 255, 0.2)),
      to(transparent));

  background: linear-gradient(90deg, transparent,
      rgba(255, 255, 255, 0.2), transparent);
  animation: skeleton-loading 0.8s infinite;
}

@keyframes skeleton-loading {
  100% {
    transform: translateX(100%);
  }
}

.loader {
  border: 3px solid #ffffff;
  border-top: 3px solid transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.e-skeleton {
  position: relative;
  background: linear-gradient(269.73deg, #E2E8F0 35.65%, rgba(241, 245, 249, 0.2) 110.36%);
}

.e-skeleton::after {
  display: block;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  transform: translateX(-100%);
  background: -webkit-gradient(linear, left top,
      right top, from(transparent),
      color-stop(rgba(255, 255, 255, 0.2)),
      to(transparent));

  background: linear-gradient(90deg, transparent,
      rgba(255, 255, 255, 0.2), transparent);
  animation: skeleton-loading 0.8s infinite;
}

.customFont * {
  color: #36394A;
  background-color: white !important;
  font-family: 'Manrope' !important;
  font-size: 16px !important;
}

@keyframes skeleton-loading {
  100% {
    transform: translateX(100%);
  }
}
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: AeonikLight;
  src: url(./assets/fonts/aeonik/AeonikTRIAL-Light.otf);
  font-weight: 200;
}

@font-face {
  font-family: Aeonik;
  src: url(./assets/fonts/aeonik/AeonikTRIAL-Regular.otf);
  font-weight: 400;
}

@font-face {
  font-family: AeonikBold;
  src: url(./assets/fonts/aeonik/AeonikTRIAL-Bold.otf);
  font-weight: 600;
}

@font-face {
  font-family: Manrope;
  src: url(./assets/fonts/manrope/Manrope-Regular.ttf);
  font-weight: 400;
}

@font-face {
  font-family: ManropeMedium;
  src: url(./assets/fonts/manrope/Manrope-Medium.ttf);
  font-weight: 400;
}

@font-face {
  font-family: ManropeBold;
  src: url(./assets/fonts/manrope/Manrope-Bold.ttf);
  font-weight: 600;
}

@font-face {
  font-family: Poppins;
  src: url(./assets/fonts/poppins/Poppins-Regular.ttf);
  font-weight: 400;
}

@font-face {
  font-family: PoppinsMedium;
  src: url(./assets/fonts/poppins/Poppins-Medium.ttf);
  font-weight: 500;
}

@font-face {
  font-family: PoppinsSemibold;
  src: url(./assets/fonts/poppins/Poppins-SemiBold.ttf);
  font-weight: 600;
}

@font-face {
  font-family: Inter;
  src: url(./assets/fonts/inter/Inter_18pt-Medium.ttf);
  font-weight: 400;
}

body {
  margin: 0;
  font-family: 'Aeonik';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  background: #E5F0FF;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}